import $ from 'jquery';
export default class AddressSelect {
    static init() {
        const $addressBookSelect = $('.js__address-book-radio');
        $addressBookSelect.on('change', e => {
            const $currentTarget = $(e.currentTarget);
            $('.js__address-id-input').val($(e.currentTarget).data('id'));
            this.addressBookClearValue($currentTarget);
            this.addressBookAutoFill($currentTarget);
        });
        $('.js__toggle-element-on-select').on('change', e => {
            this.toggleAddressElements($(e.currentTarget));
        });
        this.toggleAddressElements($('.js__toggle-element-on-select:checked'));
        this.addressBookClearValue($addressBookSelect);
        this.addressBookAutoFill($addressBookSelect);
    }
    static toggleAddressElements($addressInput) {
        if ($addressInput.length) {
            const $toggleElement = $($addressInput.data('toggle-element-visibility-on-select'));
            const displayElement = !!$addressInput.data('toggle-visible');
            $toggleElement.toggle(displayElement);
        }
    }
    static addressBookAutoFill($currentTarget) {
        const data = $currentTarget.find(':selected').data() || $currentTarget.filter(':checked').data() || $currentTarget.data();
        const $inputsWrapper = $($currentTarget.data('inputs-wrapper-selector'));
        $.each(data, (property, value) => {
            const $field = this.findByName(property, $inputsWrapper);
            if (value || $field.data('fill-any-value')) {
                $field.val(value);
                if (value) {
                    $field.siblings().addClass('active');
                }
                else {
                    $field.siblings().removeClass('active');
                }
                if ($field.is('select')) {
                    $field.val(value).trigger('change');
                    $field.closest('.required.field').find('label.select-label').addClass('active');
                }
            }
        });
    }
    static addressBookClearValue($currentTarget) {
        const $inputsWrapper = $($currentTarget.data('inputs-wrapper-selector'));
        $.each($inputsWrapper.find('input, select').not(':hidden'), (key, input) => {
            const $input = $(input);
            if (!$input.is(':radio') &&
                !$input.hasClass('reseller-choice-select') &&
                !$input.hasClass('store-choice-select') &&
                !$input.hasClass('js__ignore-autofill')) {
                $input.not('.address-book-select').val('');
                $input.siblings().removeClass('active');
                $inputsWrapper
                    .find('select:not(.address-book-select, .reseller-choice-select, .store-choice-select)')
                    .val('')
                    .trigger('change');
                $inputsWrapper
                    .find('select:not(.address-book-select, .reseller-choice-select, .store-choice-select)')
                    .closest('.required.field')
                    .find('label.select-label')
                    .removeClass('active');
            }
        });
    }
    static parseKey(key) {
        return key.replace(/(_\w)/g, words => words[1].toUpperCase());
    }
    static findByName(name, $element) {
        return $element.find(`[name*= ${this.parseKey(String(name))} ]`);
    }
}
