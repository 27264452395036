import $ from 'jquery';
import { disableAllInputs, enableAllInputs } from '../../../utils/input';
export default class RadioInputDescription {
    static $inputs = $('.js__radio-input-with-description');
    static init() {
        if (this.$inputs.length) {
            this.initDescriptionsHandle();
        }
    }
    static initDescriptionsHandle() {
        this.$inputs.each((index, element) => {
            const $input = $(element);
            $input.on('change', e => {
                const $input = $(e.currentTarget);
                this.displayDescriptionHandle($input);
            });
        });
        const $selectedInput = $('.js__radio-input-with-description:checked');
        this.displayDescriptionHandle($selectedInput);
    }
    static displayDescriptionHandle($input) {
        const inputName = $input.attr('name');
        const $groupInputs = $(`.js__radio-input-with-description[name='${inputName}']`);
        const $groupDescriptions = $groupInputs.siblings('.js__radio-input-description');
        const $groupDistantDescriptions = $input
            .closest('.js__input-description-wrapper')
            .find('.js__radio-input-distant-description');
        $groupDescriptions.addClass('d-none');
        $groupDistantDescriptions.addClass('d-none');
        if ($input.data('disable-inputs')) {
            disableAllInputs($groupDescriptions);
            disableAllInputs($groupDistantDescriptions);
        }
        if ($input.is(':checked')) {
            const $inputDescription = $input.siblings('.js__radio-input-description');
            if (!$inputDescription.length) {
                const $inputDescription = $input
                    .closest('.js__input-description-wrapper')
                    .find('.js__radio-input-distant-description');
                $inputDescription.removeClass('d-none');
                if ($input.data('disable-inputs')) {
                    enableAllInputs($inputDescription);
                }
            }
            $inputDescription.removeClass('d-none');
            if ($input.data('disable-inputs')) {
                enableAllInputs($inputDescription);
            }
        }
    }
}
