import $ from 'jquery';
export default class RadioInputsBind {
    static $inputsWrapper = $('.js__bind-different-name-radio');
    static init() {
        if (this.$inputsWrapper.length) {
            this.initRadioInputsBinding();
        }
    }
    static initRadioInputsBinding() {
        this.$inputsWrapper.each((index, wrapper) => {
            const $wrapper = $(wrapper);
            const $element = $wrapper.find('input');
            const $bindingInputNames = $wrapper.data('bind-to-radio-name')?.split(',');
            if ($bindingInputNames) {
                $bindingInputNames.forEach(name => {
                    const $inputToBind = $(`input[name="${name}"]`);
                    $inputToBind.on('change', () => {
                        if ($element.is(':checked') && $inputToBind.is(':checked')) {
                            $element.prop('checked', false).trigger('change');
                        }
                        if ($element.is(':checked') && $inputToBind.is(':checked')) {
                            $element.prop('checked', false).trigger('change');
                            $inputToBind.trigger('change');
                        }
                    });
                });
            }
        });
    }
}
